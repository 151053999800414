import e from "./svgs/aggregation.svg.js";
import t from "./svgs/candidate-results.svg.js";
import o from "./svgs/debate.svg.js";
import a from "./svgs/electoral-votes.svg.js";
import s from "./svgs/eu-parliament.svg.js";
import r from "./svgs/female-candidate.svg.js";
import n from "./svgs/horn.svg.js";
import i from "./svgs/male-candidate.svg.js";
import c from "./svgs/parliament-1.svg.js";
import l from "./svgs/parliament-2.svg.js";
import m from "./svgs/popular-votes.svg.js";
import u from "./svgs/protester-signs.svg.js";
import p from "./svgs/results-by-candidates.svg.js";
import d from "./svgs/results-piechart.svg.js";
import g from "./svgs/scales-balanced.svg.js";
import y from "./svgs/scales-unbalanced.svg.js";
import v from "./svgs/stats.svg.js";
import w from "./svgs/timeline.svg.js";
import f from "./svgs/usa-ca.svg.js";
import h from "./svgs/usa-fl.svg.js";
import k from "./svgs/usa-lower-48-states.svg.js";
import I from "./svgs/usa-ny.svg.js";
import b from "./svgs/usa-party-democratic.svg.js";
import S from "./svgs/usa-party-green.svg.js";
import U from "./svgs/usa-party-libertarian.svg.js";
import P from "./svgs/usa-party-republican.svg.js";
import C from "./svgs/usa-tx.svg.js";
import A from "./svgs/vote.svg.js";
import x from "./svgs/white-house-1.svg.js";
import B from "./svgs/white-house-2.svg.js";
const D = {
    name: "aggregation",
    value: e,
    categories: ["election"],
    keywords: ["aggregation", "election results", "results", "elections", "merge", "converge"]
  },
  E = {
    name: "candidate-results",
    value: t,
    categories: ["election"],
    keywords: ["front runner", "slate", "candidate", "results", "elections"]
  },
  H = {
    name: "debate",
    value: o,
    categories: ["election"],
    keywords: ["discussion", "dispute", "argument", "elections", "battle", "fight", "boxing"]
  },
  L = {
    name: "electoral-votes",
    value: a,
    categories: ["election"],
    keywords: ["electoral college", "electoral", "votes", "presidential election", "elections"]
  },
  R = {
    name: "eu-parliament",
    value: s,
    categories: ["election"],
    keywords: ["European Union", "Europe", "parliament", "EU", "government", "politics"]
  },
  V = {
    name: "female-candidate",
    value: r,
    categories: ["election"],
    keywords: ["female", "woman", "elections", "candidate", "speaker", "presenter"]
  },
  F = {
    name: "horn",
    value: n,
    categories: ["election"],
    keywords: ["broadcast", "megaphone", "speaker", "volume", "elections"]
  },
  N = {
    name: "male-candidate",
    value: i,
    categories: ["election"],
    keywords: ["male", "man", "elections", "candidate", "speaker", "presenter"]
  },
  T = {
    name: "parliament-1",
    value: c,
    categories: ["election"],
    keywords: ["parliament", "capitol", "goverment", "house", "politics", "Europe", "temple"]
  },
  G = {
    name: "parliament-2",
    value: l,
    categories: ["election"],
    keywords: ["parliament", "capitol", "goverment", "house", "politics", "Berlin", "gate", "monument"]
  },
  W = {
    name: "popular-votes",
    value: m,
    categories: ["election"],
    keywords: ["vote", "ballot", "cast", "station", "election"]
  },
  j = {
    name: "protester-signs",
    value: u,
    categories: ["election"],
    keywords: ["protest", "protester", "demonstration", "signs", "revolt", "turmoil", "riot"]
  },
  Y = {
    name: "results-by-candidates",
    value: p,
    categories: ["election"],
    keywords: ["results", "chart", "pie"]
  },
  q = {
    name: "results-piechart",
    value: d,
    categories: ["election"],
    keywords: ["results", "chart", "pie"]
  },
  z = {
    name: "scales-balanced",
    value: g,
    categories: ["election"],
    keywords: ["scales", "justice", "balance", "legal", "weight"]
  },
  J = {
    name: "scales-unbalanced",
    value: y,
    categories: ["election"],
    keywords: ["scales", "unfair", "unbalanced", "off-balance", "legal", "weight"]
  },
  K = {
    name: "stats",
    value: v,
    categories: ["election"],
    keywords: ["statistics", "chart", "screen", "display", "monitor"]
  },
  M = {
    name: "timeline",
    value: w,
    categories: ["election"],
    keywords: ["timeline", "events"]
  },
  O = {
    name: "usa-ca",
    value: f,
    categories: ["election"],
    keywords: ["USA", "state", "California", "ca"]
  },
  Q = {
    name: "usa-fl",
    value: h,
    categories: ["election"],
    keywords: ["USA", "state", "Florida", "fl"]
  },
  X = {
    name: "usa-lower-48-states",
    value: k,
    categories: ["election"],
    keywords: ["USA", "state", "lower", "48"]
  },
  Z = {
    name: "usa-ny",
    value: I,
    categories: ["election"],
    keywords: ["USA", "state", "New York", "ny"]
  },
  _ = {
    name: "usa-party-democratic",
    value: b,
    categories: ["election"],
    keywords: ["USA", "party", "democratic", "democrat", "democrats", "donkey", "politics"]
  },
  $ = {
    name: "usa-party-green",
    value: S,
    categories: ["election"],
    keywords: ["USA", "party", "green", "politics"]
  },
  ee = {
    name: "usa-party-libertarian",
    value: U,
    categories: ["election"],
    keywords: ["USA", "party", "libertarian", "hedgehog", "politics"]
  },
  te = {
    name: "usa-party-republican",
    value: P,
    categories: ["election"],
    keywords: ["USA", "party", "republican", "republicans", "elephant", "politics"]
  },
  oe = {
    name: "usa-tx",
    value: C,
    categories: ["election"],
    keywords: ["USA", "state", "Texas", "tx"]
  },
  ae = {
    name: "vote",
    value: A,
    categories: ["election"],
    keywords: ["vote", "cast", "ballot", "box", "station", "election"]
  },
  se = {
    name: "white-house-1",
    value: x,
    categories: ["election"],
    keywords: ["white house", "president", "house", "government", "Washington", "DC"]
  },
  re = {
    name: "white-house-2",
    value: B,
    categories: ["election"],
    keywords: ["white house", "president", "house", "government", "Washington", "DC", "Athens", "acropolis"]
  },
  Fe = [D, E, H, L, R, V, F, N, T, G, W, j, Y, q, z, J, K, M, O, Q, X, Z, _, $, ee, te, oe, ae, se, re];
export { D as aggregation, E as candidateResults, H as debate, Fe as elections, L as electoralVotes, R as euParliament, V as femaleCandidate, F as horn, N as maleCandidate, T as parliament1, G as parliament2, W as popularVotes, j as protesterSigns, Y as resultsByCandidates, q as resultsPiechart, z as scalesBalanced, J as scalesUnbalanced, K as stats, M as timeline, O as usaCa, Q as usaFl, X as usaLower48States, Z as usaNy, _ as usaPartyDemocratic, $ as usaPartyGreen, ee as usaPartyLibertarian, te as usaPartyRepublican, oe as usaTx, ae as vote, se as whiteHouse1, re as whiteHouse2 };