import { animate, group, state, style, transition, trigger } from '@angular/animations';

export const defaultAnimation = [trigger('flyInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(300, style({ opacity: 1 }))
  ]),
  transition(':leave', [
    animate(300, style({ opacity: 0 }))
  ])
])];

export const collapsableCard = [trigger('flyInOut', [
  state('void', style({ opacity: 0 })),
  state('in', style({ opacity: 1 })),
  transition('void => in', [
    animate(300, style({ opacity: 1 }))
  ])
]),
trigger('animateCollapse', [
  state('in', style({
    'max-height': '*', 'opacity': '1', 'visibility': 'visible'
  })),
  state('out', style({
    'max-height': '0px', 'opacity': '0', 'visibility': 'hidden'
  })),
  transition('in => out', [group([
    animate('400ms ease-in-out', style({
      'opacity': '0'
    })),
    animate('600ms ease-in-out', style({
      'max-height': '0px'
    })),
    animate('700ms ease-in-out', style({
      'visibility': 'hidden'
    }))
  ]
  )]),
  transition('out => in', [group([
    animate('1ms ease-in-out', style({
      'visibility': 'visible'
    })),
    animate('600ms ease-in-out', style({
      'max-height': '*'
    })),
    animate('800ms ease-in-out', style({
      'opacity': '1'
    }))
  ]
  )])
]),
trigger('openCollapse', [
  state('open', style({
    transform: 'rotate(180deg)'
  })),
  transition('* => *', [
    animate('200ms')
  ])
])];
