<div id="collapsable-card-container">
  <div [@flyInOut]="'in'" [ngClass]="{'transparent': _hasHostAttributes('transparent'), 'no-top-margin': noTopMargin }">
    <ui-card-header>
      <ng-container>
        <div class="show-hide-questions-container section-to-hide" (click)="toggleCollapse()">
          <uikit-icon [@openCollapse]="collapsed ? 'collapsed' : 'open'" [icon]="'chevron-down-thick'"></uikit-icon>
          <ng-container *ngIf="collapsed; else hideQuestions"> {{expandTitle}} </ng-container>
          <ng-template #hideQuestions> {{collapseTitle}} </ng-template>
        </div>
      </ng-container>
    </ui-card-header>

    <div class="questions-container">
      <div [@animateCollapse]="collapsed ? 'out': 'in'">
        <ng-content></ng-content>
        <ui-card-footer>
          <ng-container *ngIf="!buttonToggle else buttonToggler">
            <div class="hide-questions-container border-top section-to-hide" (click)="toggleCollapse()">
              <uikit-icon [icon]="'chevron-up-thick'"></uikit-icon>
              {{collapseTitle}}
            </div>
          </ng-container>
        </ui-card-footer>
      </div>
    </div>

  </div>
</div>

<ng-template #buttonToggler>
  <div class="footer-button">
    <button uikit-button color="secondary" [icon]="collapsed ? 'chevron-down-thick' : 'chevron-up-thick'"
      (click)="toggleCollapse()">
      {{ collapsed ? expandTitle : collapseTitle }}
    </button>
  </div>
</ng-template>