<label [attr.for]="inputId" class="ui-radio-label" #label>
  <input #input class="ui-radio-input cdk-visually-hidden" type="radio"
  [id]="inputId"
  [checked]="checked"
  [disabled]="disabled"
  [tabIndex]="tabIndex"
  [attr.name]="name"
  [required]="required"
  [attr.aria-label]="ariaLabel"
  [attr.aria-labelledby]="ariaLabelledby"
  [attr.aria-describedby]="ariaDescribedby"
  (change)="_onInputChange($event)"
  (click)="_onInputClick($event)">
  <!-- The actual 'radio' part of the control. -->
    <div class="ui-radio-container">
    <!-- The label content for radio control. -->
      <div class="ui-radio-label-content">
        <!-- Add an invisible span so JAWS can read the label -->
        <span style="display:none">&nbsp;</span>
        <ng-content></ng-content>
      </div>
    </div> 
  </label>