<div class="card-container" [@flyInOut]="'in'"
  [ngClass]="{'transparent': _hasHostAttributes('transparent'), 'no-bottom-margin': noBottomMargin}">
  <div class="card-collapsable-header" (click)="toggleCollapse()">
    <ng-content select="ui-card-header"></ng-content>
    <div class="toggle-container">
      <ng-content select="ui-card-toogle-action-area"></ng-content>
      <button uikit-button icon-button color="transparent">
        <uikit-icon [icon]="'chevron-down-thick'" [@openCollapse]="collapsed ? 'collapsed' : 'open'"></uikit-icon>
      </button>
    </div>
  </div>
  <div class="card-collapsable-body" [@animateCollapse]="collapsed ? 'out': 'in'">
    <ng-content></ng-content>
  </div>
</div>