import e from "./svgs/accessibility.svg.js";
import o from "./svgs/aids-ribbon.svg.js";
import a from "./svgs/ambulance.svg.js";
import t from "./svgs/anxiety.svg.js";
import r from "./svgs/baby-bottle.svg.js";
import i from "./svgs/bacteria.svg.js";
import s from "./svgs/bandage.svg.js";
import n from "./svgs/biohazard.svg.js";
import c from "./svgs/blood.svg.js";
import l from "./svgs/braille.svg.js";
import m from "./svgs/breastfeeding.svg.js";
import d from "./svgs/caduceus.svg.js";
import h from "./svgs/cancer-ribbon.svg.js";
import p from "./svgs/clinic.svg.js";
import u from "./svgs/condom.svg.js";
import g from "./svgs/condom-used.svg.js";
import y from "./svgs/contraception.svg.js";
import v from "./svgs/coronavirus.svg.js";
import b from "./svgs/deafness.svg.js";
import f from "./svgs/dental-floss.svg.js";
import k from "./svgs/dental-implant.svg.js";
import w from "./svgs/diabetes.svg.js";
import I from "./svgs/dna.svg.js";
import x from "./svgs/doctors-bag.svg.js";
import z from "./svgs/doctor.svg.js";
import A from "./svgs/dose.svg.js";
import B from "./svgs/embryo.svg.js";
import S from "./svgs/fertilization.svg.js";
import C from "./svgs/fitness-watch.svg.js";
import P from "./svgs/gas-mask.svg.js";
import F from "./svgs/hearing-aid.svg.js";
import M from "./svgs/heart-care.svg.js";
import N from "./svgs/heart-monitor.svg.js";
import R from "./svgs/hospital-bed.svg.js";
import L from "./svgs/hospital-sign.svg.js";
import O from "./svgs/hospital.svg.js";
import U from "./svgs/keep-away-from-children.svg.js";
import W from "./svgs/mask-1.svg.js";
import j from "./svgs/mask-2.svg.js";
import q from "./svgs/massage.svg.js";
import D from "./svgs/medical-book.svg.js";
import E from "./svgs/medicine.svg.js";
import G from "./svgs/microorganisms.svg.js";
import H from "./svgs/microscope.svg.js";
import J from "./svgs/nurse.svg.js";
import K from "./svgs/pacifier.svg.js";
import Q from "./svgs/pill.svg.js";
import T from "./svgs/plastic-surgery.svg.js";
import V from "./svgs/poop.svg.js";
import X from "./svgs/pregnancy-no-alcohol.svg.js";
import Y from "./svgs/pulse.svg.js";
import Z from "./svgs/radioactive.svg.js";
import _ from "./svgs/rh-negative.svg.js";
import $ from "./svgs/rh-positive.svg.js";
import ee from "./svgs/rod-of-asclepius.svg.js";
import oe from "./svgs/sign-language.svg.js";
import ae from "./svgs/sneeze.svg.js";
import te from "./svgs/stethoscope.svg.js";
import re from "./svgs/stretcher.svg.js";
import ie from "./svgs/syringe.svg.js";
import se from "./svgs/teeth.svg.js";
import ne from "./svgs/thermometer.svg.js";
import ce from "./svgs/toilet-paper.svg.js";
import le from "./svgs/tooth-cracked.svg.js";
import me from "./svgs/tooth.svg.js";
import de from "./svgs/veterinarian.svg.js";
import he from "./svgs/vial.svg.js";
import pe from "./svgs/virus-protection.svg.js";
import ue from "./svgs/virus-stop.svg.js";
import ge from "./svgs/virus.svg.js";
import ye from "./svgs/visually-impaired.svg.js";
import ve from "./svgs/weight-scale.svg.js";
const be = {
    name: "accessibility",
    value: e,
    categories: ["health"],
    keywords: ["accessibility", "wheelchair", "disabled", "handicapped", "chairbound", "impaired"]
  },
  fe = {
    name: "aids-ribbon",
    value: o,
    categories: ["health"],
    keywords: ["aids", "ribbon", "hiv", "care", "community"]
  },
  ke = {
    name: "ambulance",
    value: a,
    categories: ["health"],
    keywords: ["ambulance", "car", "medicine", "emergency", "vehicle", "van", "healthcare", "medical", "rescue"]
  },
  we = {
    name: "anxiety",
    value: t,
    categories: ["health"],
    keywords: ["anxiety", "brainstorm", "mental", "depression", "despair", "fatigue", "pressure", "stress", "tension", "worried"]
  },
  Ie = {
    name: "baby-bottle",
    value: r,
    categories: ["health"],
    keywords: ["baby", "bottle", "feeding", "milk"]
  },
  xe = {
    name: "bacteria",
    value: i,
    categories: ["health"],
    keywords: ["bacteria", "virus", "covid", "coronavirus", "microbe", "germs", "science", "biology"]
  },
  ze = {
    name: "bandage",
    value: s,
    categories: ["health"],
    keywords: ["bandage", "band", "bandaid", "plaster", "aid", "wound", "first aid", "healing"]
  },
  Ae = {
    name: "biohazard",
    value: n,
    categories: ["health"],
    keywords: ["biohazard", "waste"]
  },
  Be = {
    name: "blood",
    value: c,
    categories: ["health"],
    keywords: ["blood", "drop", "donation", "droplet"]
  },
  Se = {
    name: "braille",
    value: l,
    categories: ["health"],
    keywords: ["braile", "alphabet", "blind", "read", "accessibility", "impaired"]
  },
  Ce = {
    name: "breastfeeding",
    value: m,
    categories: ["health"],
    keywords: ["breastfeeding", "mother", "nurse"]
  },
  Pe = {
    name: "caduceus",
    value: d,
    categories: ["health"],
    keywords: ["caduceus", "medical", "asclepiuss", "pharmacy", "doctor", "healthcare"]
  },
  Fe = {
    name: "cancer-ribbon",
    value: h,
    categories: ["health"],
    keywords: ["cancer", "ribbon", "awareness", "breast"]
  },
  Me = {
    name: "clinic",
    value: p,
    categories: ["health"],
    keywords: ["clinic", "hospital", "medical", "building", "therapy", "health center"]
  },
  Ne = {
    name: "condom",
    value: u,
    categories: ["health"],
    keywords: ["condom", "contraceptive", "sex", "hiv", "latex", "preservative", "healthcare", "sexual", "safe"]
  },
  Re = {
    name: "condom-used",
    value: g,
    categories: ["health"],
    keywords: ["condom", "contraceptive", "sex", "hiv", "latex", "preservative", "healthcare", "sexual", "condom used", "semen", "sperm", "safe"]
  },
  Le = {
    name: "contraception",
    value: y,
    categories: ["health"],
    keywords: [""]
  },
  Oe = {
    name: "coronavirus",
    value: v,
    categories: ["health"],
    keywords: ["coronavirus", "virus", "covid", "pandemic", "corona", "germ", "infection"]
  },
  Ue = {
    name: "deafness",
    value: b,
    categories: ["health"],
    keywords: ["deafness", "deaf", "accessibility", "hearing", "impaired", "ear"]
  },
  We = {
    name: "dental-floss",
    value: f,
    categories: ["health"],
    keywords: ["dental floss", "floss", "dentist", "tooth", "care", "flossing", "oral", "hygiene", "teeth"]
  },
  je = {
    name: "dental-implant",
    value: k,
    categories: ["health"],
    keywords: ["dental", "dentist", "implant", "tooth", "care", "oral"]
  },
  qe = {
    name: "diabetes",
    value: w,
    categories: ["health"],
    keywords: ["diabetes", "hand", "finger", "blood", "healtcheck", "endocrinology", "blood test", "blood drop", "blood sugar", "glucose"]
  },
  De = {
    name: "dna",
    value: I,
    categories: ["health"],
    keywords: ["dna", "genes", "genome", "biology", "genetics"]
  },
  Ee = {
    name: "doctors-bag",
    value: x,
    categories: ["health"],
    keywords: ["doctor", "bag", "briefcase", "healthcare", "first aid kit", "medical box", "medicine", "emergency", "medical", "hospital", "nurse"]
  },
  Ge = {
    name: "doctor",
    value: z,
    categories: ["health"],
    keywords: ["doctor", "surgeon", "physician", "md", "medical", "nurse", "gp", "general practitioner"]
  },
  He = {
    name: "dose",
    value: A,
    categories: ["health"],
    keywords: ["dose", "doseage", "dropper", "drop", "tool", "medicine", "sample", "eyedropper"]
  },
  Je = {
    name: "embryo",
    value: B,
    categories: ["health"],
    keywords: ["embryo", "baby", "fetus", "motherhood", "pregnancy", "pregnant", "maternity", "mother"]
  },
  Ke = {
    name: "fertilization",
    value: S,
    categories: ["health"],
    keywords: ["fertilization", "cell", "insemination", "ovulation", "reproduction", "sperm", "egg"]
  },
  Qe = {
    name: "fitness-watch",
    value: C,
    categories: ["health"],
    keywords: ["fitness", "watch", "smartwatch", "fit band", "heart rate", "wearable", "tracker", "sport", "diet"]
  },
  Te = {
    name: "gas-mask",
    value: P,
    categories: ["health"],
    keywords: ["gas", "mask", "protection", "safety", "chemical mask", "industrial mask", "respirator"]
  },
  Ve = {
    name: "hearing-aid",
    value: F,
    categories: ["health"],
    keywords: ["hearing", "aid", "deaf", "deafness", "ear", "earbuds", "sound"]
  },
  Xe = {
    name: "heart-care",
    value: M,
    categories: ["health"],
    keywords: ["heart", "care", "check"]
  },
  Ye = {
    name: "heart-monitor",
    value: N,
    categories: ["health"],
    keywords: ["heart", "pulse", "cardiogram", "beat", "cardiac", "healthcare"]
  },
  Ze = {
    name: "hospital-bed",
    value: R,
    categories: ["health"],
    keywords: ["hospital", "bed", "care", "medical"]
  },
  _e = {
    name: "hospital-sign",
    value: L,
    categories: ["health"],
    keywords: ["hospital", "sign", "medical", "road sign"]
  },
  $e = {
    name: "hospital",
    value: O,
    categories: ["health"],
    keywords: ["hospital", "clinic", "medical", "building", "therapy", "health center"]
  },
  eo = {
    name: "keep-away-from-children",
    value: U,
    categories: ["health"],
    keywords: ["keep away from children", "keep away children", "not suitable for children", "no children", "keep away from kids", "keep away kids", "not suitable for kids", "no kids", "keep away from babys", "keep away babys", "not suitable for babys", "no babys"]
  },
  oo = {
    name: "mask",
    value: W,
    categories: ["health"],
    keywords: ["mask", "face mask", "medical mask", "shield mask", "protective mask", "dentist mask", "covid", "prevention"]
  },
  ao = {
    name: "mask-alt",
    value: j,
    categories: ["health"],
    keywords: ["mask", "face mask", "medical mask", "shield mask", "protective mask", "dentist mask", "covid", "prevention"]
  },
  to = {
    name: "massage",
    value: q,
    categories: ["health"],
    keywords: ["massage", "spa", "relax", "body massage", "treatment"]
  },
  ro = {
    name: "medical-book",
    value: D,
    categories: ["health"],
    keywords: ["medical", "book", "notebook", "healthcare", "cross", "medicine book", "pharmacy book"]
  },
  io = {
    name: "medicine",
    value: E,
    categories: ["health"],
    keywords: ["medicine", "drugs", "medical", "pill"]
  },
  so = {
    name: "microorganisms",
    value: G,
    categories: ["health"],
    keywords: ["microorganisms", "bacteria", "microbes", "virus", "biological", "cell", "biology"]
  },
  no = {
    name: "microscope",
    value: H,
    categories: ["health"],
    keywords: ["microscope", "lab", "biology", "chemistry", "instrument", "science", "education", "learn"]
  },
  co = {
    name: "nurse",
    value: J,
    categories: ["health"],
    keywords: ["nurse", "healthcare", "medical", "medical assistant", "nursemaid"]
  },
  lo = {
    name: "pacifier",
    value: K,
    categories: ["health"],
    keywords: ["pacifier", "baby", "child", "children", "kid", "binky", "dummy", "nipple", "soother", "teether"]
  },
  mo = {
    name: "pill",
    value: Q,
    categories: ["health"],
    keywords: ["pill", "drugs", "medicine", "capsule", "medical", "remedy", "medicament", "dragee", "treatment", "aid"]
  },
  ho = {
    name: "plastic-surgery",
    value: T,
    categories: ["health"],
    keywords: ["plastic surgery", "cosmetic surgery", "esthetician"]
  },
  po = {
    name: "poop",
    value: V,
    categories: ["health"],
    keywords: ["poop", "shit", "crap", "poo", "faeces"]
  },
  uo = {
    name: "pregnancy-no-alcohol",
    value: X,
    categories: ["health"],
    keywords: ["pregnancy", "preagnant", "no alcohol", "no drinking", "non-alcoholic", "do not drink"]
  },
  go = {
    name: "pulse",
    value: Y,
    categories: ["health"],
    keywords: ["pulse", "heartbeat", "heartrate"]
  },
  yo = {
    name: "radioactive",
    value: Z,
    categories: ["health"],
    keywords: ["radioactive", "biohazard", "danger", "radioactivity", "radiation", "nuke", "atomic bomb", "atom", "nuclear", "energy"]
  },
  vo = {
    name: "rh-negative",
    value: _,
    categories: ["health"],
    keywords: ["blood", "rh", "negative", "minus", "blood group", "blood type", "rhesus", "donorship"]
  },
  bo = {
    name: "rh-positive",
    value: $,
    categories: ["health"],
    keywords: ["blood", "rh", "positive", "plus", "blood group", "blood type", "rhesus", "donorship"]
  },
  fo = {
    name: "rod-of-ascelepius",
    value: ee,
    categories: ["health"],
    keywords: ["rod of ascelepius", "staff of ascelepius", "ascelepius"]
  },
  ko = {
    name: "sign-language",
    value: oe,
    categories: ["health"],
    keywords: ["sign", "language", "deaf", "asl", "deafness", "mute", "dumb"]
  },
  wo = {
    name: "sneeze",
    value: ae,
    categories: ["health"],
    keywords: ["sneeze", "cough", "covid", "corona", "coronavirus", "flu", "infection", "sick", "cold", "hayfever"]
  },
  Io = {
    name: "stethoscope",
    value: te,
    categories: ["health"],
    keywords: ["stethoscope", "line", "doctor", "medical", "nurse", "doctor", "healthcare"]
  },
  xo = {
    name: "stretcher",
    value: re,
    categories: ["health"],
    keywords: ["stretcher", "aid", "hospital", "patient", "medical", "bed", "cot", "ambulance"]
  },
  zo = {
    name: "syringe",
    value: ie,
    categories: ["health"],
    keywords: ["syringe", "medicine", "doctor", "nurse", "drugs", "injection", "vaccine", "vaccination", "flu", "medical", "needle", "shot"]
  },
  Ao = {
    name: "teeth",
    value: se,
    categories: ["health"],
    keywords: ["teeth", "false teeth", "tooth", "set of teeth", "dental", "dentist", "gums", "dentistry"]
  },
  Bo = {
    name: "thermometer",
    value: ne,
    categories: ["health"],
    keywords: ["thermometer", "temperature", "weather", "celsius", "fahrenheit", "gauge", "science", "measure", "heat", "cold", "degree"]
  },
  So = {
    name: "toilet-paper",
    value: ce,
    categories: ["health"],
    keywords: ["toilet", "paper", "napkins", "roll"]
  },
  Co = {
    name: "tooth-cracked",
    value: le,
    categories: ["health"],
    keywords: ["tooth", "cracked tooth", "caries", "dental", "dentist", "broken tooth", "damaged tooth", "teeth"]
  },
  Po = {
    name: "tooth",
    value: me,
    categories: ["health"],
    keywords: ["tooth", "teeth", "dental", "dentist"]
  },
  Fo = {
    name: "veterinarian",
    value: de,
    categories: ["health"],
    keywords: ["veterinarian", "vet", "pet", "animal", "clinic", "veterinary"]
  },
  Mo = {
    name: "vial",
    value: he,
    categories: ["health"],
    keywords: ["vial", "test tube", "lab", "sample", "chemistry"]
  },
  No = {
    name: "virus-protection",
    value: pe,
    categories: ["health"],
    keywords: ["virus", "protection", "shield", "immunity", "corona", "coronavirus", "covid", "bacteria", "safety", "antivirus", "hygiene", "stop virus"]
  },
  Ro = {
    name: "virus-stop",
    value: ue,
    categories: ["health"],
    keywords: ["virus", "stop virus", "protection", "corona", "coronavirus", "covid", "bacteria", "safety"]
  },
  Lo = {
    name: "virus",
    value: ge,
    categories: ["health"],
    keywords: ["virus", "corona", "coronavirus", "covid", "bacteria", "flu"]
  },
  Oo = {
    name: "visually-impaired",
    value: ye,
    categories: ["health"],
    keywords: ["visually", "impaired", "blind", "eye", "sight"]
  },
  Uo = {
    name: "weight-scale",
    value: ve,
    categories: ["health"],
    keywords: ["weight", "scale", "measure", "bathroom", "bath", "overweight", "obesity", "weighing", "fitness", "weightloss"]
  },
  ot = [be, fe, ke, we, Ie, xe, ze, Ae, Be, Se, Ce, Pe, Fe, Me, Ne, Re, Le, Oe, Ue, We, je, qe, De, Ge, Ee, He, Je, Ke, Qe, Te, Ve, Xe, Ye, $e, Ze, _e, eo, oo, ao, to, ro, io, so, no, co, lo, mo, ho, po, uo, go, yo, vo, bo, fo, ko, wo, Io, xo, zo, Ao, Bo, So, Po, Co, Fo, Mo, Lo, No, Ro, Oo, Uo];
export { be as accessibility, fe as aidsRibbon, ke as ambulance, we as anxiety, Ie as babyBottle, xe as bacteria, ze as bandage, Ae as biohazard, Be as blood, Se as braille, Ce as breastfeeding, Pe as caduceus, Fe as cancerRibbon, Me as clinic, Ne as condom, Re as condomUsed, Le as contraception, Oe as coronavirus, Ue as deafness, We as dentalFloss, je as dentalImplant, qe as diabetes, De as dna, Ge as doctor, Ee as doctorsBag, He as dose, Je as embryo, Ke as fertilization, Qe as fitnessWatch, Te as gasMask, ot as health, Ve as hearingAid, Xe as heartCare, Ye as heartMonitor, $e as hospital, Ze as hospitalBed, _e as hospitalSign, eo as keepAwayFromChildren, oo as mask, ao as maskAlt, to as massage, ro as medicalBook, io as medicine, so as microorganisms, no as microscope, co as nurse, lo as pacifier, mo as pill, ho as plasticSurgery, po as poop, uo as pregnancyNoAlcohol, go as pulse, yo as radioactive, vo as rhNegative, bo as rhPositive, fo as rodOfAsclepius, ko as signLanguage, wo as sneeze, Io as stethoscope, xo as stretcher, zo as syringe, Ao as teeth, Bo as thermometer, So as toiletPaper, Po as tooth, Co as toothCracked, Fo as veterinarian, Mo as vial, Lo as virus, No as virusProtection, Ro as virusStop, Oo as visuallyImpaired, Uo as weightScale };