import { Pipe, PipeTransform } from '@angular/core';
import { DataGroup, DataLabel } from 'app/shared/models';
import { getColor } from 'app/shared/ui';

@Pipe({ name: 'labelByKey' })
export class DataLabelByKeyPipe implements PipeTransform {
  transform(labels: DataLabel[], key: string): string {
    if (labels == null) { labels = []; }
    const label = labels.find(l => l.key === key);
    return label !== undefined ? label.label : 'N/A';
  }
}
@Pipe({ name: 'byName' })
export class DataGroupsByNamePipe implements PipeTransform {
    transform(dataGroups: DataGroup[], name: string): DataGroup[] {
        if (dataGroups == null) { dataGroups = []; }
        return dataGroups.filter(dg => dg.name === name || dg.dataConditionName === name);
    }
}


@Pipe({ name: 'groupByLabelKey' })
export class DataGroupsGroupedByLabelKeyPipe implements PipeTransform {
    transform(dataGroups: DataGroup[], labelKey: string): Grouping<DataGroup>[] {
        if (dataGroups == null) { dataGroups = []; }
        const labelByKeyPipe = new DataLabelByKeyPipe();
        return dataGroups.groupBy(dg => labelByKeyPipe.transform(dg.labels, labelKey));
    }
}

@Pipe({ name: 'toDataSeries' })
export class DataGroupsToDataSeriesPipe implements PipeTransform {
    transform(groupDataGroups: DataGroup[], stacked = false): DataSerieItem[] {
        const result = [];
        for (const groupDataGroup of this.emptyIfNull(groupDataGroups)) {
            if (!stacked) {
                const serieItem = <DataSerieItem>{ series: [] };
                for (const resultDataGroup of this.emptyIfNull(groupDataGroup.dataGroups)) {
                    serieItem.series.push(...this.emptyIfNull(resultDataGroup.dataPoints).map(dp => <DataSerie>{
                        name: dp.name,
                        value: dp.value,
                        category: dp.category,
                        color: getColor(dp.colorName)
                    }));
                }
                result.push(serieItem);
            } else {
                let index = 0;
                for (const resultDataGroup of this.emptyIfNull(groupDataGroup.dataGroups)) {
                    for (const dp of this.emptyIfNull(resultDataGroup.dataPoints)) {
                        if (dp.value === 0) { continue; }
                        result.push(<DataSerieItem>{
                            group: new DataLabelByKeyPipe().transform(groupDataGroup.labels, 'GroupName'),
                            series: [
                                <DataSerie>{
                                    name: dp.category,
                                    value: dp.value,
                                    color: getColor(dp.colorName),
                                    index: index++
                                }
                            ]
                        });
                    }
                }
            }
        }
        return result;
    }

    private emptyIfNull(list: any[]) {
        if (list == null) { list = []; }
        return list;
    }
}

export interface DataSerie {
    name: string;
    value: number;
    color: string;
    category: string;
    index?: number;
}

export interface DataSerieItem {
    group: string;
    series: DataSerie[];
}
