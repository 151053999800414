import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InsightDropdownModule } from '../dropdown';
import { UICheckbox, UICheckboxGroup } from './checkbox';

@NgModule({
    declarations: [UICheckboxGroup, UICheckbox],
    imports: [CommonModule, InsightDropdownModule],
    exports: [UICheckboxGroup, UICheckbox]
})
export class InsightCheckboxModule { }
