<div class="uikit-form-field-wrapper">
  <div class="uikit-flex-wrapper" [class.label-padding]="!!_labelChild">
    <label #label *ngIf="!!_labelChild" class="uikit-form-field-label" [id]="_labelId" [attr.for]="_control?.id">
      <ng-content select="uikit-label"></ng-content>
    </label>

    <div #inputContainer class="uikit-infix-wrapper" [ngClass]="{'disable': _disableInput}">
      <ng-content></ng-content>
      <div *ngIf="_displaySuffix" class="uikit-suffix">
        <ng-content select="[uikit-suffix]"></ng-content>
      </div>
    </div>

  </div>

  <div class="uikit-subscript-wrapper" *ngIf="_errorChildren?.length > 0">
    <ng-content select="uikit-error"></ng-content>
    <ng-content *ngIf="!_errorChildren.length" select="uikit-message"></ng-content>
  </div>
</div>
