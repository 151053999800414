import { Directive, Input } from '@angular/core';

let nextUniqueId = 0;

@Directive({
    selector: 'uikit-message',
    host: {
        'class': 'uikit-message',
        '[attr.id]': 'id',
    }
})
export class UIKitMessage {
    @Input() id = `uikit-message-${nextUniqueId++}`;
}
