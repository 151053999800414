import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { variableMap } from 'insight/src/app/library/shared/fancy-content-field/variableMap';
import { CompanyType } from 'app/shared/models';

@Pipe({ name: 'wrapVariables' })
export class WrapVariablesPipe implements PipeTransform {
  constructor(private translationService: TranslocoService) { }
  transform(input: string, companyType: CompanyType, languageCode: string): string {
    return input?.replaceAll(/{.*?}/g,
      (a) => {

        const map = variableMap[companyType].map(v => {
          return {
            ...v,
            translationKey: v.translationKey ?? 'INSIGHT.SURVEY.HELPTEXTS.' + v.key.replaceAll('.', '_').toUpperCase()
          }
        });
        const key = a?.replaceAll('{', '').replaceAll('}', '');
        const translationKey = map.find(v => v.key === key)?.translationKey ?? 'INSIGHT.SURVEY.HELPTEXTS.DEFAULT';
        const translation = this.translationService.translate(translationKey, {}, languageCode) ||
          this.translationService.translate('INSIGHT.SURVEY.HELPTEXTS.DEFAULT', {}, languageCode);
        return `<span class="variable">[${translation}]</span>`
      });
  }
}
