import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DataPointByLabelPipe, FilterPipe, HasPermissionDirective, RemovePtagPipe, SafePipe } from '../';
import { ByLanguagePipe } from './by-language';
import { DataGroupsByNamePipe, DataGroupsGroupedByLabelKeyPipe, DataGroupsToDataSeriesPipe, DataLabelByKeyPipe } from './data-group.pipe';
import { FirstLetterPipe } from './first-letter.pipe';
import { TruncateTextPipe } from './truncate.pipe';
import { UserContentPipe } from './user-content/user-content.pipe';
import { WrapVariablesPipe } from './wrap-variables';
import { JoinPipe } from './join.pipe';

@NgModule({
  declarations: [
    RemovePtagPipe,
    DataLabelByKeyPipe,
    HasPermissionDirective,
    DataGroupsByNamePipe,
    DataGroupsToDataSeriesPipe,
    DataPointByLabelPipe,
    DataGroupsGroupedByLabelKeyPipe,
    SafePipe,
    FilterPipe,
    TruncateTextPipe,
    ByLanguagePipe,
    UserContentPipe,
    FirstLetterPipe,
    WrapVariablesPipe,
    JoinPipe
  ],
  exports: [
    RemovePtagPipe,
    DataLabelByKeyPipe,
    HasPermissionDirective,
    DataGroupsByNamePipe,
    DataGroupsToDataSeriesPipe,
    DataPointByLabelPipe,
    DataGroupsGroupedByLabelKeyPipe,
    SafePipe,
    FilterPipe,
    TruncateTextPipe,
    ByLanguagePipe,
    UserContentPipe,
    FirstLetterPipe,
    WrapVariablesPipe,
    JoinPipe
  ],
  imports: [CommonModule],
  providers: [WrapVariablesPipe]
})
export class PipesModule { }
