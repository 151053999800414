import o from "./svgs/add-child.svg.js";
import e from "./svgs/add-filter.svg.js";
import t from "./svgs/add-row.svg.js";
import n from "./svgs/arrow-down-left.svg.js";
import r from "./svgs/arrow-down-right.svg.js";
import a from "./svgs/arrow-up-left.svg.js";
import i from "./svgs/arrow-up-right.svg.js";
import c from "./svgs/jump-down.svg.js";
import p from "./svgs/jump-up.svg.js";
import m from "./svgs/pin-left.svg.js";
import d from "./svgs/pin-right.svg.js";
import w from "./svgs/unpin-left.svg.js";
import s from "./svgs/unpin-right.svg.js";
const u = {
    name: "add-child",
    value: o,
    categories: ["content"],
    keywords: ["add", "insert", "row", "child", "new", "below", "under"]
  },
  l = {
    name: "add-filter",
    value: e,
    categories: ["content"],
    keywords: ["add", "filter", "funnel", "leads", "new"]
  },
  f = {
    name: "add-row",
    value: t,
    categories: ["content"],
    keywords: ["add", "insert", "row", "new"]
  },
  h = {
    name: "arrow-down-left",
    value: n,
    categories: ["content"],
    keywords: ["arrow", "down", "left", "dimension"]
  },
  g = {
    name: "arrow-down-right",
    value: r,
    categories: ["content"],
    keywords: ["arrow", "down", "right"]
  },
  k = {
    name: "arrow-up-left",
    value: a,
    categories: ["content"],
    keywords: ["arrow", "up", "left"]
  },
  v = {
    name: "arrow-up-right",
    value: i,
    categories: ["content"],
    keywords: ["arrow", "up", "right", "axis", "coordinate", "system"]
  },
  y = {
    name: "jump-down",
    value: c,
    categories: ["content"],
    keywords: ["jump", "down", "arrow", "below", "under"]
  },
  I = {
    name: "jump-up",
    value: p,
    categories: ["content"],
    keywords: ["jump", "up", "arrow", "above"]
  },
  R = {
    name: "pin-left",
    value: m,
    categories: ["content"],
    keywords: ["pin", "freeze", "left", "pushpin", "nail", "marker", "attach", "tack", "thumbtack"]
  },
  j = {
    name: "pin-right",
    value: d,
    categories: ["content"],
    keywords: ["pin", "freeze", "right", "pushpin", "nail", "marker", "attach", "tack", "thumbtack"]
  },
  L = {
    name: "unpin-left",
    value: w,
    categories: ["content"],
    keywords: ["unpin", "unfreeze", "left", "pushpin", "nail", "marker", "detach", "tack", "thumbtack"]
  },
  b = {
    name: "unpin-right",
    value: s,
    categories: ["content"],
    keywords: ["unpin", "unfreeze", "right", "pushpin", "nail", "marker", "detach", "tack", "thumbtack"]
  },
  K = [u, l, f, h, g, k, v, y, I, R, j, L, b];
export { u as addChild, l as addFilter, f as addRow, h as arrowDownLeft, g as arrowDownRight, k as arrowUpLeft, v as arrowUpRight, K as content, y as jumpDown, I as jumpUp, R as pinLeft, j as pinRight, L as unpinLeft, b as unpinRight };