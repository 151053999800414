import e from "./svgs/behance.svg.js";
import o from "./svgs/dribbble.svg.js";
import a from "./svgs/facebook-messenger.svg.js";
import r from "./svgs/facebook.svg.js";
import t from "./svgs/github.svg.js";
import i from "./svgs/instagram.svg.js";
import s from "./svgs/linkedin.svg.js";
import m from "./svgs/medium.svg.js";
import c from "./svgs/pinterest.svg.js";
import n from "./svgs/reddit.svg.js";
import l from "./svgs/snapchat.svg.js";
import d from "./svgs/telegram.svg.js";
import k from "./svgs/tiktok.svg.js";
import g from "./svgs/twitter.svg.js";
import w from "./svgs/viber.svg.js";
import p from "./svgs/wechat.svg.js";
import b from "./svgs/whatsapp.svg.js";
import u from "./svgs/youtube.svg.js";
const f = {
    name: "behance",
    value: e,
    categories: ["social media"],
    keywords: ["behance", "portfolio", "social", "media", "network", "logo", "trademark"]
  },
  h = {
    name: "dribbble",
    value: o,
    categories: ["social media"],
    keywords: ["dribbble", "portfolio", "basketball", "social", "media", "network", "logo", "trademark"]
  },
  y = {
    name: "facebook-messenger",
    value: a,
    categories: ["social media"],
    keywords: ["facebook", "messenger", "chat", "message", "social", "media", "network", "logo", "trademark"]
  },
  v = {
    name: "facebook",
    value: r,
    categories: ["social media"],
    keywords: ["facebook", "social", "media", "network", "logo", "trademark"]
  },
  I = {
    name: "github",
    value: t,
    categories: ["social media"],
    keywords: ["github", "repository", "git", "programming", "social", "media", "network", "logo", "trademark"]
  },
  M = {
    name: "instagram",
    value: i,
    categories: ["social media"],
    keywords: ["instagram", "social", "media", "network", "logo", "trademark"]
  },
  j = {
    name: "linkedin",
    value: s,
    categories: ["social media"],
    keywords: ["linkedin", "professional", "recruiting", "job", "employment", "social", "media", "network", "logo", "trademark"]
  },
  x = {
    name: "medium",
    value: m,
    categories: ["social media"],
    keywords: ["medium", "blog", "article", "social", "media", "network", "logo", "trademark"]
  },
  q = {
    name: "pinterest",
    value: c,
    categories: ["social media"],
    keywords: ["pinterest", "social", "media", "network", "logo", "trademark"]
  },
  z = {
    name: "reddit",
    value: n,
    categories: ["social media"],
    keywords: ["reddit", "social", "media", "network", "logo", "trademark"]
  },
  A = {
    name: "snapchat",
    value: l,
    categories: ["logos", "social media"],
    keywords: ["snapchat", "ghost", "social", "media", "network", "logo", "trademark"]
  },
  B = {
    name: "telegram",
    value: d,
    categories: ["logos", "social media"],
    keywords: ["telegram", "messenger", "chat", "message", "social", "media", "network", "logo", "trademark"]
  },
  C = {
    name: "tiktok",
    value: k,
    categories: ["social media"],
    keywords: ["tiktok", "social", "media", "network", "logo", "trademark"]
  },
  D = {
    name: "twitter",
    value: g,
    categories: ["social media"],
    keywords: ["twitter", "social", "media", "network", "logo", "trademark"]
  },
  E = {
    name: "viber",
    value: w,
    categories: ["social media"],
    keywords: ["viber", "messenger", "chat", "message", "social", "media", "network", "logo", "trademark"]
  },
  F = {
    name: "wechat",
    value: p,
    categories: ["social media"],
    keywords: ["wechat", "messenger", "chat", "message", "social", "media", "network", "logo", "trademark"]
  },
  G = {
    name: "whatsapp",
    value: b,
    categories: ["social media"],
    keywords: ["whatsapp", "messenger", "chat", "message", "social", "media", "network", "logo", "trademark"]
  },
  H = {
    name: "youtube",
    value: u,
    categories: ["social media"],
    keywords: ["youtube", "video", "stream", "social", "media", "network", "logo", "trademark"]
  },
  ee = [f, h, y, v, I, M, j, x, q, z, A, B, C, D, E, F, G, H];
export { f as behance, h as dribbble, v as facebook, y as facebookMessenger, I as github, M as instagram, j as linkedin, x as medium, q as pinterest, z as reddit, A as snapchat, ee as socialMedia, B as telegram, C as tiktok, D as twitter, E as viber, F as wechat, G as whatsapp, H as youtube };