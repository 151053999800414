import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { OverlayModule } from '@angular/cdk/overlay';
import { PipesModule } from 'app/shared/core/pipes/pipes.module';
import { UIKitIconModule } from 'app/shared/ui-kit/icon';
import { InsightSearchFieldModule } from '../search-field/search-field.module';
import { UIDropdown } from './dropdown';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
    declarations: [UIDropdown],
    imports: [CommonModule, OverlayModule, TranslocoModule, PipesModule, FormsModule, InsightSearchFieldModule, UIKitIconModule, NgScrollbarModule],
    exports: [UIDropdown]
})
export class InsightDropdownModule { }
