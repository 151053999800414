import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UISearchFieldWrapper } from './search-field-wrapper/search-field-wrapper.component';
import { UISearchField } from './search-field.directive';
import { InsightIconButtonModule } from '../icon-button/icon-button.module';

@NgModule({
  declarations: [UISearchFieldWrapper, UISearchField],
  imports: [
    CommonModule,
    InsightIconButtonModule
  ],
  exports: [UISearchField]
})
export class InsightSearchFieldModule { }
