import { Observable } from 'rxjs';
import { NgControl } from '@angular/forms';


/** An interface which allows a control to work inside of a `UIKitFormField`. */
export abstract class UIKitFormFieldControl<T> {
    /** The value of the control. */
    value: T | null;

    readonly stateChanges: Observable<void>;
    readonly id: string;
    readonly placeholder: string;
    readonly ngControl: NgControl | null;

    readonly errorState: boolean;
    readonly focused: boolean;
    readonly empty: boolean;
    readonly required: boolean;
    readonly disabled: boolean;
    readonly controlType?: string;
    readonly invalid?: boolean;
}
