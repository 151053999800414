import { Pipe, PipeTransform } from '@angular/core';

interface HasContentItems {
  contentItems: { languageId: number, text: string }[]
}
@Pipe({
  name: 'userContent'
})
export class UserContentPipe implements PipeTransform {

  transform<T extends HasContentItems>(item: T, languageId: number): string {
    if (!item.contentItems.length) { return '' }
    return item.contentItems.find(ci => languageId === ci?.languageId)?.text ?? '';
  }
}
