const colors = {
    'nps-promoters': '#16AA4E',
    'nps-passive': '#C4C4C4',
    'nps-detractors': '#FB091F',
    '3grade-high': '#00A139',
    '3grade-medium': '#FFD800',
    '3grade-low': '#F42737',
    '5grade-highest': '#00A139',
    '5grade-high': '#A0DAB3',
    '5grade-medium': '#FFD800',
    '5grade-low': '#FF6A13',
    '5grade-lowest': '#F42737',

    'tonality-positive': '#A0DAB3',
    'tonality-neutral': '#C4C4C4',
    'tonality-negative': '#FF6A13',

    // TODO: Remove
    'ei-index-bar': '#8FE5A1',
    'ei-darkgreen' : '#16AA4E',
    'ei-green' : '#8FE5A1',
    'ei-blue' : '#1F4AE9',
    'ei-yellow' : '#FFD800',
    'ei-red' : '#FB091F',
    'scaled-index-darkgreen' : '#16AA4E',
    'scaled-index-green' : '#8FE5A1',
    'scaled-index-yellow' : '#FFD800',
    'scaled-index-red' : '#FB091F',
    'transparent': 'rgba(0, 0, 0, 0)'
};

export function getColor(c: string): string {
    let color = '#CCC';
    if (c == null) { return color; }
    if (c.startsWith('#')) { return c; }
    if (colors.hasOwnProperty(c)) { color = colors[c]; }
    return color;
}
