import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ui-checkbox-group',
  exportAs: 'uiCheckboxGroup',
  templateUrl: './checkbox-group.html',
  styleUrls: ['./checkbox-group.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UICheckboxGroup implements OnDestroy, OnInit {

  @HostBinding('class.ui-checkbox-group') get checkboxGroup() { return true; }
  @Input() model: any[] = [];
  @Input() keyField = 'key';
  @Input() checkedField = 'checked';
  @Input() labelField = 'label';
  @Output() readonly change: EventEmitter<string[]> = new EventEmitter<string[]>();
  protected unsubscribe$: Subject<void> = new Subject<void>();

  mobile = false;
  constructor(private breakpointObserver: BreakpointObserver, private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        this.mobile = result.matches;
        this.changeDetector.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkboxChange(item: any, event: boolean) {
    item[this.checkedField] = event;
    this.emitChanges();
  }

  emitChanges() {
    this.change.emit(this.model.filter(x => x[this.checkedField]).map(x => x[this.keyField]));
  }

  trackByKeyField(item: any) {
    return item[this.keyField];
  }
}

@Component({
  selector: 'ui-checkbox',
  exportAs: 'uiCheckbox',
  templateUrl: 'checkbox.html',
  styleUrls: ['checkbox.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: UICheckbox, multi: true }
  ]
})
export class UICheckbox implements ControlValueAccessor {
  private _checked = false;
  showContent: boolean;

  @HostBinding('class.ui-checkbox') get checkBox() { return true; }
  @Input()
  get checked(): boolean { return this.invert ? !this._checked : this._checked; }
  set checked(value: boolean) {
    this._checked = value;
  }
  @Input() disabled = false;
  @Input() invert = false;
  @Input() indeterminate = false;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() readonly change: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('contentWrapper', { static: true }) contentWrapper;

  constructor(private changeDetectionRef: ChangeDetectorRef) { }

  onChanges: (changes: unknown) => void;

  onInputChange(event: Event) {
    // We always have to stop propagation on the change event.
    // Otherwise the change event, from the input element, will bubble up and
    // emit its event object to the `change` output.
    event.stopPropagation();
    const isChecked = (event.target as HTMLInputElement).checked;
    this.checked = this.invert ? !isChecked : isChecked;
    this.change.emit(this._checked);
    if (this.onChanges) {
      this.onChanges(this._checked);
    }
  }

  writeValue(obj: unknown): void {
    this.checked = !!obj;
    this.changeDetectionRef.markForCheck();
  }

  registerOnChange(fn: () => void): void {
    this.onChanges = fn;
  }

  registerOnTouched(_: () => void): void {
    // not implemented
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectionRef.detectChanges();
  }

}
