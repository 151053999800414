import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    HostBinding,
    Injectable,
    InjectionToken,
    Input,
    QueryList,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormGroupDirective, NgForm } from '@angular/forms';
import { NeedsToUnsubscribe } from 'app/shared/core';
import { merge } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { UIKitError, UIKitFormFieldControl, UIKitMessage, UIKitPrefix, UIKitSuffix } from '.';
import { UIKitLabel } from '../label/label';


let nextUniqueId = 0;
export const UIKIT_FORM_FIELD = new InjectionToken<UIKitFormField>('UIKitFormField');
@Component({
    selector: 'uikit-form-field',
    templateUrl: 'form-field.html',
    styleUrls: [
        'form-field.scss',
        '../styles/_error.scss',
        '../styles/_input.scss'
    ],
    host: {
        '[class.uikit-form-field-invalid]': '_control?.errorState',
        '[class.uikit-form-field-disabled]': '_control?.disabled',
        '[class.ng-invalid]': '_control?.invalid',
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
    { provide: UIKIT_FORM_FIELD, useExisting: UIKitFormField }
  ]
})
export class UIKitFormField extends NeedsToUnsubscribe implements AfterContentInit {
    _disableInput: boolean;
    _displaySuffix: boolean;
    _labelId = `uikit-form-field-label-${nextUniqueId++}`;
    private _explicitFormFieldControl: UIKitFormFieldControl<any>;

    @ViewChild('inputContainer', { static: true }) _inputContainerRef: ElementRef;
    @ViewChild('label', { static: true }) _label: ElementRef;
    @ContentChildren(UIKitError) _errorChildren: QueryList<UIKitError>;
    @ContentChildren(UIKitMessage) _messageChildren: QueryList<UIKitMessage>;

    @ContentChildren(UIKitSuffix) _suffixChildren: QueryList<UIKitSuffix>;
    @ContentChildren(UIKitPrefix) _prefixChildren: QueryList<UIKitPrefix>;

    @ContentChild(UIKitLabel) _labelChild: UIKitLabel;
    @ContentChild(UIKitFormFieldControl) _formFieldControl: UIKitFormFieldControl<any>;

    @HostBinding('class.compact')
    @Input() compact = false;

    get _control() {
        return this._explicitFormFieldControl || this._formFieldControl;
    }
    set _control(value) {
        this._explicitFormFieldControl = value;
    }

    constructor(public _elementRef: ElementRef, private _changeDetectorRef: ChangeDetectorRef) { super(); }

    ngAfterContentInit() {
        const control = this._control;
        if (control) {
            if (control.controlType) {
                this._elementRef.nativeElement.classList.add(`uikit-form-field-type-${control.controlType}`);
            }
            if (control.ngControl && control.ngControl.valueChanges) {
                control.ngControl.valueChanges
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(() => this._changeDetectorRef.markForCheck());
            }
            control.stateChanges.pipe(startWith(null)).subscribe(() => {
                this._changeDetectorRef.markForCheck();
            });
        }

        // Update when the number of messages changes.
        this._messageChildren.changes.pipe(startWith(null)).subscribe(() => {
            this._changeDetectorRef.markForCheck();
        });

        merge(this._prefixChildren.changes, this._suffixChildren.changes).subscribe(() => {
            this._changeDetectorRef.markForCheck();
        });

        // Update when the number of errors changes.
        this._errorChildren.changes.pipe(startWith(null)).subscribe(() => {
            this._changeDetectorRef.markForCheck();
        });

        // Hide suffix content when it binds nothing
        this._displaySuffix = this._suffixChildren.length > 0;

        // Let disable style sync with ngForm
        const _input = this._inputContainerRef.nativeElement.getElementsByTagName('input')[0];
        this._disableInput = _input ? _input.disabled : false;
    }
}
@Injectable({ providedIn: 'root' })
export class ErrorStateMatcher {
  isErrorState(control: AbstractControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.touched || (form && form.submitted)));
  }
}
