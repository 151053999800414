import { ComponentRef, Directive, OnInit, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { UISearchFieldWrapper } from './search-field-wrapper/search-field-wrapper.component';

@Directive({
  selector: '[ui-search-field]'
})
export class UISearchField implements OnInit {
  @Input('ui-search-field') expandFromButton: boolean;
  private searchFieldContainer: ComponentRef<UISearchFieldWrapper>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit() {
    this.searchFieldContainer = this.viewContainerRef.createComponent(UISearchFieldWrapper);

    this.searchFieldContainer.instance.template = this.templateRef;
    this.searchFieldContainer.instance.compressed = this.expandFromButton;
  }
}
