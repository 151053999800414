import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
    UICard,
    UICardActionArea,
    UICardContent,
    UICardFooter,
    UICardHeader,
    UICardSubTitle,
    UICardTableSearch,
    UICardTitle,
    UICardColumn,
    UICardIconColumn,
    UICardCentralCollapsable,
    UICardSideCollapsable,
    UICardTabContent,
    UICardToogleActionArea
} from './card';
import { UIKitButtonModule } from 'app/shared/ui-kit';
import { UIKitIconModule } from 'app/shared/ui-kit/icon';



@NgModule({
    declarations:
        [
            UICard,
            UICardCentralCollapsable,
            UICardSideCollapsable,
            UICardHeader,
            UICardTitle,
            UICardSubTitle,
            UICardContent,
            UICardTabContent,
            UICardActionArea,
            UICardTableSearch,
            UICardFooter,
            UICardColumn,
            UICardIconColumn,
            UICardToogleActionArea
        ],
    imports: [CommonModule, UIKitButtonModule, UIKitIconModule],
    exports:
        [
            UICard,
            UICardCentralCollapsable,
            UICardSideCollapsable,
            UICardHeader,
            UICardTitle,
            UICardSubTitle,
            UICardContent,
            UICardTabContent,
            UICardActionArea,
            UICardTableSearch,
            UICardFooter,
            UICardColumn,
            UICardIconColumn,
            UICardToogleActionArea
        ]
})
export class InsightCardModule { }
