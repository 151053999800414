import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ui-search-field-wrapper',
  templateUrl: './search-field-wrapper.component.html',
  styleUrls: ['./search-field-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UISearchFieldWrapper implements AfterViewInit {
  @Input() template: TemplateRef<any>;
  @Input() compressed: boolean;
  @ViewChild(TemplateRef, { static: true }) inputTemplate: TemplateRef<HTMLElement>;
  showClear$: Observable<boolean>;
  constructor() { }

  ngAfterViewInit(): void {
    this.showClear$ = fromEvent<Event>(this.inputTemplate.elementRef.nativeElement.nextSibling, 'input').pipe(
      map(event => event.target['value'].length > 0)
    );
  }

  clear(): void {
    this.inputTemplate.elementRef.nativeElement.nextSibling.value = '';
    this.inputTemplate.elementRef.nativeElement.nextSibling.dispatchEvent(new Event('input'));
  }
  showSearch(): void {
    this.compressed = false;
    setTimeout(() => this.inputTemplate.elementRef.nativeElement.nextSibling.focus());
  }

}
