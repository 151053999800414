import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIIconButton } from './icon-button.component';

@NgModule({
  declarations: [UIIconButton],
  imports: [
    CommonModule
  ],
  exports: [UIIconButton]
})
export class InsightIconButtonModule { }
