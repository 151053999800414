import { NumberInput } from '@angular/cdk/coercion';
import { Component, Directive, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, SecurityContext, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ScrollToService } from 'app/shared/services/scroll-to.service';
import * as Animation from './card-animations';
import { UICardTheme } from './card-theme';

const CARD_HOST_ATTRIBUTES = [
  'transparent',
  'column',
  'ui-summary-card',
  'icon-card',
  'mobile-reverse',
  'action-card',
  'full-width',
  'ui-table',
  'fixed-height',
  'with-header-width',
  'no-padding',
  'no-side-margin'
];

@Directive({
  selector: 'ui-card-title'
})
export class UICardTitle {
  @HostBinding('class.ui-card-title') get uiCardTitle() { return true; }
}

@Directive({
  selector: 'ui-card-subtitle'
})
export class UICardSubTitle {
  @HostBinding('class.ui-card-subtitle') get uiCardSubTitle() { return true; }
}

@Directive({
  selector: 'ui-card-content'
})
export class UICardContent {
  @HostBinding('class.ui-card-content') get uiCardContent() { return true; }
}
@Directive({
  selector: 'ui-card-tab-content'
})
export class UICardTabContent {
  @HostBinding('class') classes = 'ui-card-content ui-card-tab-content';
}
@Directive({
  selector: '[icon-column]'
})
export class UICardIconColumn {
  @HostBinding('class.icon-column') get iconColumn() { return true; }
}

@Component({
  selector: 'ui-card-header',
  templateUrl: './card-header.html',
  styleUrls: ['./card-header.scss']
})
export class UICardHeader {
  @HostBinding('class.card-container-header') get cardContainerHeader() { return true; }
}

@Component({
  selector: 'ui-card-column',
  templateUrl: './card-column.html'
})
export class UICardColumn {
  @HostBinding('class.card-column') get cardColumn() { return true; }
  @HostBinding('style.flex') @Input() ratio: number | string
  public set value(v: NumberInput) {
    this.ratio = +v;
  }
}

@Component({
  selector: 'ui-card-action-area',
  templateUrl: './card-action-area.html',
  styleUrls: ['./card-action-area.scss']
})
export class UICardActionArea {
  @HostBinding('class.inline') @Input() inline: boolean = false;
}

@Component({
  selector: 'ui-search',
  templateUrl: './card-table-search.html'
})

export class UICardTableSearch {
  @Input() inline = false;
  @Input() collapsable = false;
  @Input() collapsed = false;
  @Input() placeholder: string;
  @Input() simple = true;
  @Output() keyupEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('searchField', { static: true }) _el: ElementRef;

  focus() {
    this._el.nativeElement.focus();
  }
  collapse() {
    if (!this.collapsable) { return; }
    this.collapsed = !this.collapsed;
    if (!this.collapsed) { this._el.nativeElement.focus(); }
  }
}

@Component({
  selector: 'ui-card-footer',
  templateUrl: './card-footer.html'
})
export class UICardFooter {
  @HostBinding('class.ui-card-footer') get uiCardFooter() { return true; }
}

@Component({
  selector: 'ui-card',
  templateUrl: './card.html',
  styleUrls: ['./card.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [Animation.defaultAnimation]
})

export class UICard implements OnInit {
  @Input() theme: UICardTheme;
  @Input() readonly noBottomMargin: boolean = false;
  @HostBinding('class.ui-card') get value() { return true; }

  constructor(private elementRef: ElementRef, private sanitizer: DomSanitizer) {
    for (const attr of CARD_HOST_ATTRIBUTES) {
      if (this._hasHostAttributes(attr)) {
        (elementRef.nativeElement as HTMLElement).classList.add(attr);
      }
    }
  }
  ngOnInit(): void {
    if (this.theme) {
      (this.elementRef.nativeElement as HTMLElement)
        .classList.add(this.sanitizer.sanitize(SecurityContext.STYLE, 'theme-' + this.theme));
    }
  }
  getHostElement() {
    return this.elementRef.nativeElement;
  }

  _hasHostAttributes(...attributes: string[]) {
    return attributes.some(attribute => this.getHostElement().hasAttribute(attribute));
  }

  toggleContent() {
    this.elementRef.nativeElement.classList.toggle('open-secondary')
  }
}

@Component({
  selector: 'ui-card-collapsable-v1',
  templateUrl: './card-collapsable-v1.html',
  styleUrls: ['./card.scss', './card-collapsable-v1.scss'],
  animations: [Animation.collapsableCard]
})

export class UICardCentralCollapsable extends UICard {
  @Input() collapsed = true;
  @Input() buttonToggle = false;
  @Input() readonly noTopMargin: boolean = false;
  @Input() readonly expandTitle: string;
  @Input() readonly collapseTitle: string;
  @Output() collapsedChange = new EventEmitter<boolean>();
  @HostBinding('class.ui-card') get uiCard() { return true; }

  constructor(private _elementRef: ElementRef,
    private readonly _sanitizer: DomSanitizer,
    private readonly scrollToService: ScrollToService) {
    super(_elementRef, _sanitizer);
  }

  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
    this.collapsedChange.emit(this.collapsed);
    if (!this.collapsed) {
      setTimeout(() => this.scrollToService.scrollToTop(this._elementRef.nativeElement), 100);
    }
  }
}

@Component({
  selector: 'ui-card-toogle-action-area',
  templateUrl: './card-action-toogle-area.html'
})
export class UICardToogleActionArea {
  @HostBinding('class.action-toogle-area') get actionToggleArea() { return true; }
}

@Component({
  selector: 'ui-card-collapsable-v2',
  templateUrl: './card-collapsable-v2.html',
  styleUrls: ['./card-collapsable-v2.scss'],
  animations: [Animation.collapsableCard]
})

export class UICardSideCollapsable extends UICard {
  @Input() collapsed = true;
  @Output() collapsedChange = new EventEmitter<boolean>();

  constructor(private _elementRef: ElementRef,
    private readonly _sanitizer: DomSanitizer,
    private readonly scrollToService: ScrollToService) {
    super(_elementRef, _sanitizer);
  }

  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
    this.collapsedChange.emit(this.collapsed);
    if (!this.collapsed) {
      setTimeout(() => this.scrollToService.scrollToTop(this._elementRef.nativeElement), 100);
    }
  }
}
