<div class="card-container" [@flyInOut]="'in'"
  [ngClass]="{'transparent': _hasHostAttributes('transparent'), 'no-bottom-margin': noBottomMargin}">

  <ng-content select="ui-card-header"></ng-content>
  <ng-content select=".row-content"></ng-content>
  <div class="column-wrapper">
    <ng-content select="ui-card-column"></ng-content>
  </div>
  <ng-content></ng-content>
</div>
