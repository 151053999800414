import o from "./svgs/api.svg.js";
import r from "./svgs/app-symbol.svg.js";
import m from "./svgs/bug.svg.js";
import i from "./svgs/code-fork.svg.js";
import a from "./svgs/code-review.svg.js";
import t from "./svgs/code.svg.js";
import n from "./svgs/console.svg.js";
import g from "./svgs/development.svg.js";
import s from "./svgs/file-changes.svg.js";
import c from "./svgs/file-tree.svg.js";
import p from "./svgs/git-branch.svg.js";
import l from "./svgs/git-commit.svg.js";
import d from "./svgs/git-compare.svg.js";
import u from "./svgs/git-issue.svg.js";
import v from "./svgs/git-merge.svg.js";
import f from "./svgs/grid.svg.js";
import w from "./svgs/inspect-code.svg.js";
import y from "./svgs/milestone.svg.js";
import k from "./svgs/module.svg.js";
import I from "./svgs/plugin.svg.js";
import h from "./svgs/pull-request.svg.js";
import b from "./svgs/repository.svg.js";
import C from "./svgs/responsive-layout.svg.js";
import q from "./svgs/severity.svg.js";
import z from "./svgs/software-architecture.svg.js";
import A from "./svgs/software-installer.svg.js";
import M from "./svgs/source-code.svg.js";
import R from "./svgs/system-update.svg.js";
import x from "./svgs/verified-account.svg.js";
import B from "./svgs/workflow.svg.js";
const F = {
    name: "api",
    value: o,
    categories: ["programming"],
    keywords: ["api", "development", "interface", "service", "database", "programming"]
  },
  L = {
    name: "app-symbol",
    value: r,
    categories: ["programming"],
    keywords: ["appsymbol", "app", "development", "programming", "circle", "a"]
  },
  S = {
    name: "bug",
    value: m,
    categories: ["programming"],
    keywords: ["bug", "animal", "insect", "virus", "malware", "security", "development", "programming"]
  },
  T = {
    name: "code-fork",
    value: i,
    categories: ["programming"],
    keywords: ["codefork", "code", "fork", "git", "github", "development", "collaboration", "control", "version", "programming"]
  },
  U = {
    name: "code-review",
    value: a,
    categories: ["programming"],
    keywords: ["codereview", "review", "software", "app", "development", "programming"]
  },
  j = {
    name: "code",
    value: t,
    categories: ["programming"],
    keywords: ["code", "script", "source", "development", "programming"]
  },
  D = {
    name: "terminal",
    value: n,
    categories: ["programming"],
    keywords: ["terminal", "console", "code", "bash", "cmd", "command", "sh", "shell", "development", "programming"]
  },
  E = {
    name: "development",
    value: g,
    categories: ["programming"],
    keywords: ["puzzle", "pieces", "jigsaw", "combination", "play", "game", "toy", "development", "programming"]
  },
  G = {
    name: "file-changes",
    value: s,
    categories: ["programming"],
    keywords: ["file", "changes", "plus", "minus", "development", "programming"]
  },
  H = {
    name: "file-tree",
    value: c,
    categories: ["programming"],
    keywords: ["file", "tree", "hierarchy", "files", "system", "levels", "development", "programming", "chart", "organize", "structure", "treeview"]
  },
  J = {
    name: "git-branch",
    value: p,
    categories: ["programming"],
    keywords: ["git", "branch", "development", "programming", "commit", "fork", "split", "code", "collaboration"]
  },
  e = {
    name: "git-commit",
    value: l,
    categories: ["programming"],
    keywords: ["git", "commit", "development", "programming", "pull", "code", "collaboration"]
  },
  Ue = {
    name: "git-compare",
    value: d,
    categories: ["programming"],
    keywords: ["git", "compare", "development", "programming", "code", "collaboration"]
  },
  K = {
    name: "git-issue",
    value: u,
    categories: ["programming"],
    keywords: ["git", "issue", "development", "programming", "warning", "exclamation"]
  },
  N = {
    name: "git-merge",
    value: v,
    categories: ["programming"],
    keywords: ["git", "merge", "development", "programming", "code", "collaboration"]
  },
  O = {
    name: "grid",
    value: f,
    categories: ["programming"],
    keywords: ["grid", "table", "development", "programming", "component", "datatable"]
  },
  P = {
    name: "inspect-code",
    value: w,
    categories: ["programming"],
    keywords: ["inspect", "code", "development", "programming", "marketing", "markup", "search", "seo", "review"]
  },
  Q = {
    name: "milestone",
    value: y,
    categories: ["programming"],
    keywords: ["milestone", "sign", "development", "programming", "travel", "roadmap", "tourism"]
  },
  V = {
    name: "code-value",
    value: k,
    categories: ["programming"],
    keywords: ["code", "value", "development", "programming", "module", "chip", "cpu", "electronics", "equipment", "hardware", "iot"]
  },
  W = {
    name: "plugin",
    value: I,
    categories: ["programming"],
    keywords: ["plugin", "plug", "development", "programming", "socket", "connector", "electricity"]
  },
  X = {
    name: "pull-request",
    value: h,
    categories: ["programming"],
    keywords: ["pull", "request", "development", "programming", "git", "commit", "scheme"]
  },
  Y = {
    name: "repository",
    value: b,
    categories: ["programming"],
    keywords: ["repo", "repository", "development", "programming", "cabinet", "document", "folder", "storage"]
  },
  Z = {
    name: "responsive-layout",
    value: C,
    categories: ["programming"],
    keywords: ["responsive", "layout", "development", "programming", "device", "adaptive", "screen", "fluid"]
  },
  _ = {
    name: "severity",
    value: q,
    categories: ["programming"],
    keywords: ["severity", "gauge", "development", "programming", "indicator", "speed", "speedometer"]
  },
  $ = {
    name: "software-architecture",
    value: z,
    categories: ["programming"],
    keywords: ["software", "architecture", "development", "programming", "horizontal"]
  },
  ee = {
    name: "software-installer",
    value: A,
    categories: ["programming"],
    keywords: ["software", "installer", "development", "programming", "install", "download"]
  },
  oe = {
    name: "source-code",
    value: M,
    categories: ["programming"],
    keywords: ["source", "code", "development", "programming", "html", "web"]
  },
  re = {
    name: "system-update",
    value: R,
    categories: ["programming"],
    keywords: ["system", "update", "development", "programming", "software", "arrows", "refresh", "sync"]
  },
  me = {
    name: "verified-account",
    value: x,
    categories: ["programming"],
    keywords: ["verified", "account", "development", "programming", "certified", "verify", "check", "sign", "badge", "quality"]
  },
  ie = {
    name: "workflow",
    value: B,
    categories: ["programming"],
    keywords: ["workflow", "hierarchy", "development", "programming", "organisation", "process", "diagram"]
  },
  je = [F, L, S, j, T, V, U, E, G, H, J, e, e, K, N, O, P, Q, W, X, Y, Z, _, $, ee, oe, re, D, me, ie];
export { F as api, L as appSymbol, S as bug, j as code, T as codeFork, V as codeModule, U as codeReview, E as development, G as fileChanges, H as fileTree, J as gitBranch, e as gitCommit, Ue as gitCompare, K as gitIssue, N as gitMerge, O as grid, P as inspectCode, Q as milestone, W as plugin, je as programming, X as pullRequest, Y as repository, Z as responsiveLayout, _ as severity, $ as softwareArchitecture, ee as softwareInstaller, oe as sourceCode, re as systemUpdate, D as terminal, me as verifiedAccount, ie as workflow };