import { PipeTransform, Pipe } from '@angular/core';

interface HasLanguageId {
  languageId: number;
}
@Pipe({ name: 'byLanguage' })
export class ByLanguagePipe implements PipeTransform {
  transform<T extends HasLanguageId>(items: Array<T>, language: number): T[] {
    if (items == null) { items = []; }
    return items.filter(ci => ci.languageId === language);
  }
}
