<div class="dropdown-label" *ngIf="showLabel">
  <label for="dropdown" *ngIf="label">{{ label }}<span *ngIf="isRequired" class="required"> *</span></label>
</div>
<div [ngClass]="{ disabled: disabled$ | async, error: showErrors }" class="dropdown-options" cdkOverlayOrigin id="dropdown"
  #trigger="cdkOverlayOrigin" (click)="toggle(trigger)" tabindex="0"
  [class.option-selected]="!!(selectedItems$ | async).length">
  <div class="selected-value">{{ selectedLabels$ | async | transloco }}</div>
  <ng-container *ngIf="!search else searchIcon">
    <span class="chevron">
      <uikit-icon [icon]="'chevron-down-thick'"></uikit-icon>
    </span>
  </ng-container>
  <ng-template #searchIcon>
    <span class="search">
      <uikit-icon [icon]="'search'"></uikit-icon>
    </span>
  </ng-template>
</div>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="triggerOrigin" [cdkConnectedOverlayHasBackdrop]="true"
  (backdropClick)="dropdownShownSubject.next(false)" [cdkConnectedOverlayOpen]="dropdownShown$ | async"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  [cdkConnectedOverlayPanelClass]="'justify-center'" [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  [cdkConnectedOverlayWidth]="
    triggerOrigin != null
      ? (dropdownWidth ?? triggerOrigin.elementRef.nativeElement.offsetWidth)
      : 0
  " [cdkConnectedOverlayOffsetY]="-1">

  <div class="dropdown-list-container">
    <div *ngIf="(search$ | async) && (options$ | async)?.length > SEARCH_FIELD_CONSTANT">
      <input #searchField *ui-search-field="false" [(ngModel)]="searchText"
        [placeholder]="'INSIGHT.PORTAL.PLACEHOLDER_SEARCH' | transloco" />
    </div>
    <ul [ngStyle]="{'height.px': calculateHeight( (options$ | async)?.length) } " [class.survey]="survey"
      [ngClass]="{ 'minimal': minimal }">
      <ng-scrollbar class="dropdown-scrollbar">
        <cdk-virtual-scroll-viewport itemSize="54" scrollViewport>
          <li *cdkVirtualFor="let item of options$ | async | filter : labelField : searchText; trackBy: trackBy "
            [class.multi-select]="multi$ | async" [class.non-existant-option]="item.__nonExistantOption"
            [class.selected]="isSelected(item)">
            <div class="item-content" (click)="selectItem(item)">
              <ng-container *ngIf="!(multi$ | async)">
                <span [class.selected]="isSelected(item)">{{ item.__nonExistantOption ? item[valueField] : getLabel(item)
                  |
                  transloco }}</span>
              </ng-container>
              <ng-container *ngIf="multi$ | async">
                <span [class.selected]="isSelected(item)">{{ item.__nonExistantOption ? item[valueField] : getLabel(item)
                  |
                  transloco }}</span>
                <input type="checkbox" [checked]="isSelected(item)" />
                <span class="checkmark"></span>
              </ng-container>
            </div>
          </li>
        </cdk-virtual-scroll-viewport>
      </ng-scrollbar>
    </ul>
  </div>
</ng-template>
<div class="errors" *ngIf="showErrors">
  <ng-content> </ng-content>
</div>
