<ng-container *ngIf="!mobile">
  <div class="group-label">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
  <div class="group-options">
    <ui-checkbox
      *ngFor="let item of model; trackBy: trackByKeyField"
      [checked]="item[checkedField]"
      [disabled]="item['disabled']"
      (change)="checkboxChange(item, $event)"
      >{{ item[labelField] }}</ui-checkbox
    >
  </div>
</ng-container>
<ng-container *ngIf="mobile">
  <ui-dropdown
    [multi]="true"
    [model]="model"
    [valueField]="keyField"
    [labelField]="labelField"
    (change)="emitChanges()"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ui-dropdown>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
