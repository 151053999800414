import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkOverlayOrigin, Overlay, ScrollStrategy } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NeedsToUnsubscribe } from 'app/shared/core/needs-to-unsubscribe';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, share, shareReplay, take, takeUntil, withLatestFrom } from 'rxjs/operators';

const DEFAULT_PLACEHOLDER = 'INSIGHT.PORTAL.FOLLOWUP.INTRO.CHOOSE';
const NO_OPTIONS = 'INSIGHT.GLOBAL.NO_OPTIONS';

const SEARCH_FIELD_CONSTANT = 9;
const OPTION_ITEM_HEIGHT_PADDED = 54;
const OPTION_ITEM_HEIGHT_PADDED_MINIMAL = 44;
const MAX_OPTIONS_VISIBLE = 4;

@Component({
  selector: 'ui-dropdown',
  templateUrl: './dropdown.html',
  styleUrls: ['./dropdown.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: UIDropdown, multi: true }
  ]
})
export class UIDropdown extends NeedsToUnsubscribe implements OnInit, ControlValueAccessor, OnChanges {

  private _optionsSubject = new BehaviorSubject<any[]>([]);
  private _multiSubject = new BehaviorSubject<boolean>(false);
  private _searchSubject = new BehaviorSubject<boolean>(false);
  private _disabledSubject = new BehaviorSubject<boolean>(false);
  dropdownShownSubject = new BehaviorSubject<boolean>(false);
  private _selectedValuesSubject = new BehaviorSubject<any[]>([]);
  private _touchedSubject = new Subject<void>();
  options$ = this._optionsSubject.asObservable();
  multi$ = this._multiSubject.asObservable();
  disabled$ = this._disabledSubject.asObservable();
  search$ = this._searchSubject.asObservable();
  dropdownShown$ = this.dropdownShownSubject.asObservable();
  touched$ = this._touchedSubject.asObservable();
  searchText = '';
  SEARCH_FIELD_CONSTANT = SEARCH_FIELD_CONSTANT;

  @HostBinding('class.ui-dropdown') get uiDropdown() { return true; }
  @HostBinding('class.minimal') @Input() minimal;
  @HostBinding('class.expanded') expanded;
  @HostBinding('class.survey') @Input() survey;
  @HostBinding('class.host-selected') @Input() selected;

  @ViewChild('searchField', { static: false })
  set searchFieldRef(element: ElementRef<HTMLInputElement>) {
    if (this.focusSearch) {
      if (element) {
        element.nativeElement.focus();
      }
    }
  }

  @Input() focusSearch = false;
  @Input() showLabel = true;
  @Input() addNoneOption = false;
  @Input() valueField: string;
  @Input() labelField: string | Function = 'label';
  @Input()
  set multi(value) {
    this._multiSubject.next(!!value);
  }
  @Input()
  set search(value) {
    this._searchSubject.next(!!value);
  }
  get search() {
    return this._searchSubject.value;
  }
  @Input()
  set disabled(value) {
    this._disabledSubject.next(!!value);
  }
  get disabled() {
    return this._disabledSubject.getValue();
  }
  @Input()
  set model(value: any[]) {
    if (value) {
      if (this.addNoneOption) {
        const noneOption = {};
        if (typeof this.labelField === 'string') {
          noneOption[this.labelField] = 'INSIGHT.GLOBAL.NA';
        }
        noneOption[this.valueField] = null;
        this._optionsSubject.next([noneOption, ...value]);
      } else {
        this._optionsSubject.next(value);
      }
    }
  }
  @Input() addMissingOptions: boolean;
  @Input() defaultSelection: any;
  @Input() preSelected: boolean;
  @Input() keepSelection: boolean;
  @Input() placeholder: string;
  @Output() readonly change: EventEmitter<any[] | any> = new EventEmitter();
  @Input() isRequired: boolean;
  @Input() showErrors: boolean;
  @Input() label: string;
  @Input() dropdownWidth: number = null;

  private _outputValues = false;
  @Input() get outputValues(): boolean {
    return this._outputValues;
  }
  set outputValues(value: BooleanInput) {
    this._outputValues = coerceBooleanProperty(value);
  }

  onChanges: (changes: any) => void;
  triggerOrigin: CdkOverlayOrigin;
  scrollStrategy: ScrollStrategy = this.overlay.scrollStrategies.reposition({
    autoClose: true
  });

  selectedItems$ =
    combineLatest([this._selectedValuesSubject, this.options$])
      .pipe(
        filter(([_, options]) => !!options),
        map(([values, options]) => {
          return options.filter(option =>
            values?.includes(option[this.valueField])
          );
        }),
        distinctUntilChanged((previousItems, currentItems) => {
          return this.arraysEqual(previousItems, currentItems);
        }),
        shareReplay(1)
      );

  selectedLabels$ =
    combineLatest([
      this.selectedItems$,
      this.options$]
    ).pipe(
      map(([selectedItems, options]) => {
        if (selectedItems.length) {
          return selectedItems
            .filter(item => !!item)
            .map(item => this.getLabel(item))
            .join(', ');
        }

        if (options?.length === 0) {
          return NO_OPTIONS;
        }
        return this.placeholder || DEFAULT_PLACEHOLDER;
      })
    );

  selectedValuesString$ = this._selectedValuesSubject.pipe(
    map(values => values.join(',')),
    shareReplay(1)
  );

  outputChanged$ = this.selectedItems$.pipe(
    map(items => {
      if (this.outputValues) {
        return items.map(item => item[this.valueField]);
      }
      return items;
    }),
    withLatestFrom(this.multi$),
    map(([items, isMultiEnabled]) => {
      if (isMultiEnabled) {
        return items;
      }
      return items[0];
    }),
    distinctUntilChanged(),
    share()
  );
  constructor(
    private overlay: Overlay,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.dropdownShown$.pipe(
      filter(x => x !== this.expanded),
      takeUntil(this.unsubscribe$)
    ).subscribe(o => this.expanded = o);
  }

  ngOnChanges(changes: SimpleChanges) {
    const { defaultSelection, model } = changes;
    const options = this._optionsSubject.getValue();
    const isMulti = this._multiSubject.getValue();
    let selectedValues;
    if (defaultSelection?.currentValue || defaultSelection?.previousValue) {
      if (isMulti) {
        selectedValues = this.defaultSelection.map(
          item => typeof item === 'object' ? item[this.valueField] : item
        );
        this._selectedValuesSubject.next(selectedValues);
        return;
      }
      const selectedValue = typeof this.defaultSelection === 'object' && this.defaultSelection != null ?
        this.defaultSelection[this.valueField] :
        this.defaultSelection;
      this._selectedValuesSubject.next([selectedValue]);
      this.changeDetector.markForCheck();
      return;
    }
    if (!this.preSelected || !options.length || isMulti) {
      if (model && JSON.stringify(model.currentValue) !== JSON.stringify(model.previousValue) && !this.keepSelection) {
        this.unsetDropdown();
      }
      return;
    }
    selectedValues = this._selectedValuesSubject.getValue();
    const nothingSelected = selectedValues.length === 0;
    if (
      nothingSelected ||
      !options.find(item => item[this.valueField] === selectedValues[0])
    ) {
      const selectedValue = options[0][this.valueField];
      this._selectedValuesSubject.next([selectedValue]);
    }
  }

  updateView() {
    this.changeDetector.detectChanges();
  }

  writeValue(obj: any): void {
    if (obj !== null && obj !== undefined) {
      if (this.addMissingOptions) {
        const isMulti = this._multiSubject.getValue();
        const options = this._optionsSubject.getValue();
        let objects = obj;
        if (!isMulti) {
          objects = [obj];
        }
        for (const o of objects) {
          if (!options.find(item => item[this.valueField] === o)) {
            const newItem = { [this.valueField]: o, __nonExistantOption: true };
            this._optionsSubject.next([newItem, ...options]);
          }
        }
      }
      return this._selectedValuesSubject.next(
        this._multiSubject.getValue() ? obj : [obj]
      );
    }
    this.unsetDropdown();
  }

  registerOnChange(fn: any): void {
    this.onChanges = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => fn());
  }

  toggle(trigger: CdkOverlayOrigin) {
    if (this.disabled) {
      return;
    }
    this.triggerOrigin = trigger;
    this._touchedSubject.next();
    this.dropdownShownSubject.next(!this.dropdownShownSubject.getValue());
    this.clearSearch();
  }

  clearSearch() {
    this.searchText = '';
  }

  unsetDropdown() {
    this._selectedValuesSubject.next([]);
  }

  selectItem(item: any) {
    const selectedValue = item[this.valueField];
    const isMulti = this._multiSubject.getValue();
    if (isMulti) {
      const selectedValues = this._selectedValuesSubject.getValue();
      if (selectedValues.includes(selectedValue)) {
        this._selectedValuesSubject.next(
          selectedValues.filter(value => value !== selectedValue)
        );
      } else {
        this._selectedValuesSubject.next([...selectedValues, selectedValue]);
      }
    } else {
      this._selectedValuesSubject.next([selectedValue]);
    }

    if (!isMulti) {
      this.dropdownShownSubject.next(false);
    }

    this.outputChanged$.pipe(take(1)).subscribe(output => {
      this.change.emit(output);
      if (this.onChanges) {
        this.onChanges(output);
      }
    });
  }

  isSelected(item: any): boolean {
    return this._selectedValuesSubject
      .getValue()
      .includes(item[this.valueField]);
  }

  trackBy = (index: number, item: any) => {
    if(this.valueField)
      return item[this.valueField];

    return index;
  }

  private arraysEqual(arr1: any[], arr2: any[]): boolean {
    const isSame = JSON.stringify(arr1) === JSON.stringify(arr2);
    return isSame;
  }

  private objectsEqual(obj1: any, obj2: any): boolean {
    const isSame = JSON.stringify(obj1) === JSON.stringify(obj2);
    return isSame;
  }

  calculateHeight(optionCount: number): number {
    const length = optionCount > SEARCH_FIELD_CONSTANT ? optionCount + 1 : optionCount;
    if (optionCount < MAX_OPTIONS_VISIBLE) {
      return length * (this.minimal ? OPTION_ITEM_HEIGHT_PADDED_MINIMAL : OPTION_ITEM_HEIGHT_PADDED)
    } else {
      return MAX_OPTIONS_VISIBLE * (this.minimal ? OPTION_ITEM_HEIGHT_PADDED_MINIMAL : OPTION_ITEM_HEIGHT_PADDED) + 2
    }
  }

  getLabel(item: any) {
    if (typeof this.labelField === 'function') {
      return this.labelField(item);
    } else {
      return item[this.labelField];
    }
  }
}
