import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollToService {
  private scrollElement: HTMLElement;

  constructor() {}

  setScrollElement(el: HTMLElement): void {
    this.scrollElement = el;
  }

  scrollToTop(el: HTMLElement, customOffset?: number): void {
    if (!this.scrollElement) { return; }
    this.scrollElement.scrollTo({
      top: (el.offsetTop - (customOffset || 0)),
      behavior: 'smooth'
    });
  }
}
